// termsOfUse.js

export const termsOfUse = {
    fr: {
        // ----- Mentions légales -----
        mentionLegalTitle: "Mentions légales",

        mentionLegalEditorTitle: "Éditeur du site",
        mentionLegalEditorText: `Le site "The Digital Training Companion" est édité par :
École Polytechnique Fédérale de Lausanne (EPFL)
Centre LEARN
Route Cantonale, 1015 Lausanne, Suisse
Téléphone : +41 (0)21 693 11 11
Email : sunny.avry@epfl.ch`,

        mentionLegalHostingTitle: "Hébergement",
        mentionLegalHostingText: `Le site est hébergé par :
Infomaniak Network SA
Rue Eugène-Marziano 25
1227 Les Acacias (GE)
Suisse`,

        mentionLegalDirectorTitle: "Responsable de la publication",
        mentionLegalDirectorText:
            `Dr. Sunny Avry
            École Polytechnique Fédérale de Lausanne (EPFL)`,

        // ----- CGU -----
        cguTitle: "Conditions Générales d'Utilisation (CGU)",

        article1Title: "Article 1 : Objet",
        article1Text: `Les présentes CGU régissent l'utilisation du site "The Digital Training Companion",
plateforme d'évaluation des formations développée par le Centre LEARN de l'EPFL.`,

        article2Title: "Article 2 : Accès au site",
        article2Text: `2.1 L'accès au site est libre et gratuit pour tous les utilisateurs.
2.2 Certaines fonctionnalités peuvent nécessiter la création d'un compte utilisateur.
2.3 Les comptes en mode "bac à sable" (sandbox) sont des comptes temporaires qui peuvent
être supprimés à tout moment sans préavis. Les utilisateurs de ces comptes acceptent leur
caractère temporaire et la possible suppression de leurs données.`,

        article3Title: "Article 3 : Services proposés",
        article3Text: `Le site propose des fonctionnalités d'évaluation des formations. L'EPFL se réserve
le droit de modifier temporairement les services en cas de maintenance nécessaire, avec
notification préalable des utilisateurs.`,

        article4Title: "Article 4 : Propriété intellectuelle",
        article4Text: `4.1 Tous les contenus présents sur le site (textes, images, vidéos, etc.) sont la
propriété exclusive de l'EPFL ou de leurs auteurs respectifs.`,

        article5Title: "Article 5 : Protection des données personnelles",
        article5Text: `5.1 Collecte des données personnelles
Les données personnelles suivantes sont collectées :
- Nom
- Prénom
- Adresse email

5.2 Finalité du traitement
Ces données sont collectées et traitées uniquement aux fins d'authentification sur le site.

5.3 Durée de conservation
Les données personnelles sont conservées sans limite de durée ou jusqu'à la demande de
suppression par l'utilisateur.

5.4 Droits des utilisateurs
Conformément à la législation suisse sur la protection des données et au RGPD, les utilisateurs
disposent des droits suivants :
- Droit d'accès à leurs données personnelles
- Droit de rectification
- Droit à l'effacement ("droit à l'oubli")
- Droit à la portabilité des données
- Droit d'opposition au traitement

Pour exercer ces droits, contactez-nous à : sunny.avry@epfl.ch

5.5 Stockage local
Le site n'utilise pas de cookies classiques mais fait usage du stockage local du navigateur
pour enregistrer un token d'authentification strictement nécessaire au bon fonctionnement du site.
Ce stockage permet de maintenir la session utilisateur active.

5.6 Services tiers et transfert de données
Le site utilise le service AI Tools d'Infomaniak pour certaines fonctionnalités. Cela implique :
- La transmission de certaines données vers les serveurs d'Infomaniak
- Un transfert de données vers les serveurs d'Infomaniak en Suisse
- Le traitement des données selon les conditions d'utilisation d'Infomaniak

Les services d'IA d'Infomaniak garantissent une confidentialité totale des données. Conformément
à leur politique, aucune donnée envoyée via l'API n'est stockée, ni utilisée à des fins de
formation ou d'analyse. Le traitement des données s'effectue en temps réel, exclusivement sur
des serveurs situés en Suisse, dans des infrastructures certifiées ISO 27001. Infomaniak
s'engage à respecter le RGPD ainsi que la législation suisse sur la protection des données.

5.7 Comptes sandbox
Pour les comptes sandbox, les données peuvent être supprimées à tout moment sans préavis.`,

        article6Title: "Article 6 : Obligations des utilisateurs",
        article6Text: `Les utilisateurs s'engagent à :
- Ne pas perturber le fonctionnement du site
- Ne pas tenter d'accéder à des parties non autorisées du site
- Respecter les droits de propriété intellectuelle
- Ne pas diffuser de contenu illégal ou préjudiciable`,

        article7Title: "Article 7 : Responsabilité",
        article7Text: `7.1 L'EPFL ne peut être tenue responsable des dommages directs ou indirects résultant de
l'utilisation du site.
7.2 L'EPFL s'efforce d'assurer la disponibilité du site mais ne peut garantir un accès
ininterrompu.
7.3 L'EPFL n'est pas responsable de la perte de données des comptes sandbox qui peuvent être
supprimés à tout moment.`,

        article8Title: "Article 8 : Modification des CGU",
        article8Text: `L'EPFL se réserve le droit de modifier les présentes CGU à tout moment. Les utilisateurs
seront informés des modifications substantielles.`,

        article9Title: "Article 9 : Interruption du service",
        article9Text: `9.1 En cas d'arrêt définitif du service, l'EPFL s'engage à :
- Informer les utilisateurs au minimum 30 jours avant la date d'arrêt
- Permettre aux utilisateurs d'accéder à leurs données pendant cette période
- Préciser les modalités de conservation et de suppression des données
- Fournir un contact pour toute question relative à l'arrêt du service

9.2 La notification d'arrêt sera effectuée par email aux utilisateurs enregistrés et par
affichage sur le site.`,

        article10Title: "Article 10 : Droit applicable et juridiction compétente",
        article10Text: `10.1 Les présentes CGU sont soumises au droit suisse.
10.2 Tout litige relatif à l'interprétation ou l'exécution des présentes CGU relève de la
compétence exclusive des tribunaux de Lausanne, Suisse.`,

        lastUpdate: "Date de dernière mise à jour : 23 novembre 2024"
    },

    // --------------------------------------------------------------------

    en: {
        mentionLegalTitle: "Legal Notice",

        mentionLegalEditorTitle: "Publisher of the site",
        mentionLegalEditorText: `The website "The Digital Training Companion" is published by:
- École Polytechnique Fédérale de Lausanne (EPFL)
- LEARN Center
- Route Cantonale, 1015 Lausanne, Switzerland
- Phone: +41 (0)21 693 11 11
- Email: sunny.avry@epfl.ch`,

        mentionLegalHostingTitle: "Hosting",
        mentionLegalHostingText: `The site is hosted by:
Infomaniak Network SA
Rue Eugène-Marziano 25
1227 Les Acacias (GE)
Switzerland`,

        mentionLegalDirectorTitle: "Publication Director",
        mentionLegalDirectorText: `Dr. Sunny Avry
École Polytechnique Fédérale de Lausanne (EPFL)`,

        cguTitle: "General Terms of Use (GTU)",

        article1Title: "Article 1: Purpose",
        article1Text: `These GTU govern the use of "The Digital Training Companion," a training-evaluation
platform developed by EPFL's LEARN Center.`,

        article2Title: "Article 2: Site Access",
        article2Text: `2.1 Access to the site is free for all users.
2.2 Some features may require creating a user account.
2.3 "Sandbox" accounts are temporary accounts that may be deleted at any time without notice.
Users of these accounts acknowledge their temporary nature and the possible deletion of their data.`,

        article3Title: "Article 3: Services Offered",
        article3Text: `The site provides functionalities to evaluate training programs. EPFL reserves the
right to modify these services temporarily for necessary maintenance, with prior notification
to users.`,

        article4Title: "Article 4: Intellectual Property",
        article4Text: `4.1 All content on the site (texts, images, videos, etc.) is the exclusive property
of EPFL or their respective authors.`,

        article5Title: "Article 5: Protection of Personal Data",
        article5Text: `5.1 Collection of personal data
The following personal data is collected:
- First name
- Last name
- Email address

5.2 Purpose of processing
This data is collected and processed solely for authentication purposes on the site.

5.3 Retention period
Personal data is retained indefinitely or until the user requests its deletion.

5.4 User rights
In accordance with Swiss data protection legislation and the GDPR, users have the following rights:
- Right of access to their personal data
- Right to rectification
- Right to erasure ("right to be forgotten")
- Right to data portability
- Right to object to processing

To exercise these rights, contact: sunny.avry@epfl.ch

5.5 Local storage
The site does not use traditional cookies but uses the browser's local storage to save an
authentication token strictly necessary for the proper functioning of the site. This storage
keeps the user session active.

5.6 Third-party services and data transfer
The site uses Infomaniak's AI Tools for certain functionalities. This implies:
- The transmission of some data to Infomaniak's servers
- Data transfer to Infomaniak servers in Switzerland
- Data processing under Infomaniak's terms of use

Infomaniak's AI services guarantee full confidentiality of data. According to their policy,
no data sent via the API is stored or used for training or analysis. Data is processed in
real time, exclusively on servers located in Switzerland, in ISO 27001-certified infrastructures.
Infomaniak is committed to complying with the GDPR as well as Swiss data protection legislation.

5.7 Sandbox accounts
For sandbox accounts, data may be deleted at any time without notice.`,

        article6Title: "Article 6: User Obligations",
        article6Text: `Users agree to:
- Not disrupt the operation of the site
- Not attempt to access unauthorized parts of the site
- Respect intellectual property rights
- Not distribute illegal or harmful content`,

        article7Title: "Article 7: Liability",
        article7Text: `7.1 EPFL cannot be held responsible for direct or indirect damages resulting from
the use of the site.
7.2 EPFL strives to ensure site availability but cannot guarantee uninterrupted access.
7.3 EPFL is not responsible for the loss of data in sandbox accounts, which may be deleted
at any time.`,

        article8Title: "Article 8: Modification of the GTU",
        article8Text: `EPFL reserves the right to modify these GTU at any time. Users will be informed
of substantial changes.`,

        article9Title: "Article 9: Service Interruption",
        article9Text: `9.1 In the event of permanent service discontinuation, EPFL commits to:
- Inform users at least 30 days before the discontinuation date
- Allow users to access their data during that period
- Specify the conditions for data retention and deletion
- Provide a contact for any questions related to the service discontinuation

9.2 Notice of discontinuation will be given via email to registered users and by posting
on the site.`,

        article10Title: "Article 10: Applicable Law and Jurisdiction",
        article10Text: `10.1 These GTU are governed by Swiss law.
10.2 Any dispute regarding the interpretation or execution of these GTU falls under the
exclusive jurisdiction of the courts of Lausanne, Switzerland.`,

        lastUpdate: "Last updated: November 23, 2024"
    },

    // --------------------------------------------------------------------

    de: {
        mentionLegalTitle: "Rechtliche Hinweise",

        mentionLegalEditorTitle: "Herausgeber der Website",
        mentionLegalEditorText: `Die Website "The Digital Training Companion" wird herausgegeben von:
- École Polytechnique Fédérale de Lausanne (EPFL)
- LEARN-Zentrum
- Route Cantonale, 1015 Lausanne, Schweiz
- Telefon: +41 (0)21 693 11 11
- E-Mail: sunny.avry@epfl.ch`,

        mentionLegalHostingTitle: "Hosting",
        mentionLegalHostingText: `Die Website wird gehostet von:
Infomaniak Network SA
Rue Eugène-Marziano 25
1227 Les Acacias (GE)
Schweiz`,

        mentionLegalDirectorTitle: "Verantwortlicher für die Veröffentlichung",
        mentionLegalDirectorText: `Dr. Sunny Avry
École Polytechnique Fédérale de Lausanne (EPFL)`,

        cguTitle: "Allgemeine Nutzungsbedingungen (ANB)",

        article1Title: "Artikel 1: Zweck",
        article1Text: `Diese ANB regeln die Nutzung von "The Digital Training Companion", einer Plattform
zur Bewertung von Schulungen, die vom LEARN-Zentrum der EPFL entwickelt wurde.`,

        article2Title: "Artikel 2: Zugriff auf die Website",
        article2Text: `2.1 Der Zugriff auf die Website ist für alle Benutzer kostenlos.
2.2 Einige Funktionen können das Anlegen eines Benutzerkontos erfordern.
2.3 "Sandbox"-Konten sind temporäre Konten, die jederzeit ohne Vorankündigung gelöscht
werden können. Benutzer dieser Konten akzeptieren deren vorübergehenden Charakter und die
mögliche Löschung ihrer Daten.`,

        article3Title: "Artikel 3: Angebotene Dienste",
        article3Text: `Die Website bietet Funktionen zur Bewertung von Schulungen. Die EPFL behält sich
das Recht vor, diese Dienste bei Bedarf vorübergehend zu ändern (z.B. für Wartungsarbeiten), 
und informiert die Benutzer im Voraus.`,

        article4Title: "Artikel 4: Geistiges Eigentum",
        article4Text: `4.1 Sämtliche Inhalte auf der Website (Texte, Bilder, Videos usw.) sind Eigentum 
der EPFL oder ihrer jeweiligen Autoren.`,

        article5Title: "Artikel 5: Schutz personenbezogener Daten",
        article5Text: `5.1 Erhebung personenbezogener Daten
Folgende personenbezogene Daten werden erhoben:
- Vorname
- Nachname
- E-Mail-Adresse

5.2 Zweck der Verarbeitung
Diese Daten werden ausschließlich zum Zweck der Authentifizierung auf der Website erhoben und verarbeitet.

5.3 Aufbewahrungsdauer
Die personenbezogenen Daten werden zeitlich unbegrenzt oder bis zur Löschanfrage durch den Benutzer aufbewahrt.

5.4 Rechte der Benutzer
Gemäß schweizerischem Datenschutzrecht und der DSGVO haben Benutzer folgende Rechte:
- Recht auf Auskunft über ihre personenbezogenen Daten
- Recht auf Berichtigung
- Recht auf Löschung ("Recht auf Vergessenwerden")
- Recht auf Datenübertragbarkeit
- Widerspruchsrecht

Zur Ausübung dieser Rechte wenden Sie sich bitte an: sunny.avry@epfl.ch

5.5 Lokaler Speicher
Die Website verwendet keine herkömmlichen Cookies, sondern den lokalen Speicher des Browsers,
um ein Authentifizierungs-Token zu speichern, das für die ordnungsgemäße Funktion der Website
unbedingt erforderlich ist. Dieser Speicher ermöglicht es, die Benutzersitzung aufrechtzuerhalten.

5.6 Dienste Dritter und Datenübertragung
Die Website nutzt die AI-Tools von Infomaniak für bestimmte Funktionen. Dies bedeutet:
- Die Übermittlung bestimmter Daten an die Server von Infomaniak
- Die Übertragung von Daten an Infomaniak-Server in der Schweiz
- Die Verarbeitung der Daten gemäß den Nutzungsbedingungen von Infomaniak

Die KI-Dienste von Infomaniak gewährleisten die vollständige Vertraulichkeit der Daten.
Nach deren Richtlinie werden über die API gesendete Daten weder gespeichert noch für Training
oder Analysen verwendet. Die Datenverarbeitung erfolgt in Echtzeit, ausschließlich auf
Servern in der Schweiz, in ISO-27001-zertifizierten Infrastrukturen. Infomaniak verpflichtet
sich zur Einhaltung der DSGVO sowie des schweizerischen Datenschutzrechts.

5.7 Sandbox-Konten
Bei Sandbox-Konten können Daten jederzeit ohne Vorankündigung gelöscht werden.`,

        article6Title: "Artikel 6: Pflichten der Benutzer",
        article6Text: `Die Benutzer verpflichten sich:
- Den Betrieb der Website nicht zu stören
- Nicht zu versuchen, auf nicht autorisierte Teile der Website zuzugreifen
- Geistige Eigentumsrechte zu respektieren
- Keine illegalen oder schädlichen Inhalte zu verbreiten`,

        article7Title: "Artikel 7: Haftung",
        article7Text: `7.1 Die EPFL haftet nicht für direkte oder indirekte Schäden, die durch die Nutzung
der Website entstehen.
7.2 Die EPFL bemüht sich um die Verfügbarkeit der Website, kann jedoch keinen
ununterbrochenen Zugriff garantieren.
7.3 Die EPFL ist nicht verantwortlich für den Verlust von Daten in Sandbox-Konten, die
jederzeit gelöscht werden können.`,

        article8Title: "Artikel 8: Änderung der ANB",
        article8Text: `Die EPFL behält sich das Recht vor, diese ANB jederzeit zu ändern. Die Benutzer
werden über wesentliche Änderungen informiert.`,

        article9Title: "Artikel 9: Unterbrechung des Dienstes",
        article9Text: `9.1 Im Falle einer endgültigen Einstellung des Dienstes verpflichtet sich die EPFL:
- Die Benutzer mindestens 30 Tage vor dem Enddatum zu informieren
- Den Benutzern während dieses Zeitraums den Zugriff auf ihre Daten zu ermöglichen
- Die Bedingungen für die Aufbewahrung und Löschung von Daten anzugeben
- Einen Kontakt für alle Fragen zur Einstellung des Dienstes bereitzustellen

9.2 Die Mitteilung über die Einstellung erfolgt per E-Mail an registrierte Benutzer und
durch einen Hinweis auf der Website.`,

        article10Title: "Artikel 10: Anwendbares Recht und Gerichtsstand",
        article10Text: `10.1 Diese ANB unterliegen dem schweizerischen Recht.
10.2 Alle Streitigkeiten im Zusammenhang mit der Auslegung oder Ausführung dieser ANB unterliegen
der ausschließlichen Zuständigkeit der Gerichte von Lausanne, Schweiz.`,

        lastUpdate: "Letzte Aktualisierung: 23. November 2024"
    }
};
