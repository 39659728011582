import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Box, Button, Typography, TextField } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import SurveyQuestion from '../../components/SurveyQuestion';
import DTCLogo from '../../components/DTCLogo';
import { BACKEND_URL } from "../../config";
import { buttonStyle } from '../../components/styledComponents'
import { useMessageService } from '../../services/MessageService';
import { useLanguage } from '../../contexts/LanguageContext';
import { useAuthUser } from '../../contexts/AuthUserContext';

const MemoizedSurveyQuestion = React.memo(SurveyQuestion);

const useLanguageFromUrl = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const urlLanguageCode = params.get('lng');
    const { languageCode: contextLanguageCode, setLanguageCode } = useLanguage();

    useEffect(() => {
        if (urlLanguageCode && urlLanguageCode !== contextLanguageCode) {
            setLanguageCode(urlLanguageCode);
        }
    }, [urlLanguageCode, contextLanguageCode, setLanguageCode]);

    return { languageCode: urlLanguageCode || contextLanguageCode };
};

const CompleteSurvey = () => {
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const monitoring = params.get('monitoring');
    const sandbox = params.get('sandbox');
    const link = params.get('link') === 'true';
    const userId = params.get('userId');
    const email = params.get('email');
    const { languageCode } = useLanguageFromUrl();

    // states for the displayName
    const [displayName, setDisplayName] = useState('');
    const [nameSubmitted, setNameSubmitted] = useState(false);
    // states for the assessments
    const [assessmentType, setAssessmentType] = useState(""); 
    const [assessmentName, setAssessmentName] = useState("");
    const [assessmentStatus, setAssessmentStatus] = useState("");

    const navigate = useNavigate();
    const [surveyData, setSurveyData] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [currentAssessmentIndex, setCurrentAssessmentIndex] = useState(0);
    const assessmentIds = params.getAll('assessment[]');
    const [showGlobalError, setShowGlobalError] = useState(false);
    const [linkingIDValidated, setLinkingIDValidated] = useState(!link);
    const [linkingCode, setLinkingCode] = useState('');
    const [isCodeValid, setIsCodeValid] = useState(false); 
    const [errorMessage, setErrorMessage] = useState('');

    const { getMessage } = useMessageService();
    const { currentUser } = useAuthUser();

    const fetchSurveyData = useCallback(async () => {
        const currentAssessmentId = assessmentIds[currentAssessmentIndex];
        try {
            const response = await axios.get(`${BACKEND_URL}/survey`, {
                params: { 
                    currentAssessmentServerId: currentAssessmentId, 
                    currentMonitoringServerId: monitoring,
                    sandbox: sandbox 
                },
            });
    
            const { survey, type, name, status } = response.data;
            setSurveyData(survey.map(question => ({
                ...question,
                choices: question.choices.map(choice => ({ value: choice, label: choice })),
            })));
    
            setAssessmentType(type); 
            setAssessmentName(name); 
            setAssessmentStatus(status); 
    
            const initialValues = survey.reduce((values, question) => {
                // Set default values based on question type and mandatory flag
                let defaultValue = question.isMandatory ? '' : question.choices[0];
                return { ...values, [`q${question.questionId}`]: defaultValue };
            }, {});
    
            setInitialValues(initialValues);
        } catch (error) {
            console.error('Error fetching survey data:', error);
        }
    }, [assessmentIds, currentAssessmentIndex, monitoring]);
    
    // Additional useEffect to ensure data is fetched when the name is submitted
    useEffect(() => {
        if (nameSubmitted) {
            fetchSurveyData();
        }
    }, [nameSubmitted, fetchSurveyData]);


    const handleSubmitName = (event) => {
        event.preventDefault();
        setNameSubmitted(true); // Proceed to the survey
    };


    const handleSubmit = useCallback(async (values, { setSubmitting }) => {

        const isAnyMandatoryUnanswered = surveyData.some(question => (
            question.isMandatory && (!values[`q${question.questionId}`] || values[`q${question.questionId}`] === '')
        ));

        if (isAnyMandatoryUnanswered) {
            setShowGlobalError(true);
            setSubmitting(false); // should be set to false to allow re-submit
            return;
        }

        setShowGlobalError(false); 

        const currentAssessmentId = assessmentIds[currentAssessmentIndex];
        
        const responses = surveyData.map(question => ({
            questionId: question.questionId,
            linkingId: linkingCode,
            shortName: question.shortName,
            question: question.question,
            context: question.context,
            correctAnswer: question.correctAnswer,
            workshop: question.workshop,
            questionType: question.questionType,
            learningType: question.learningType,
            adoptionType: question.adoptionType,
            organizationalType: question.organizationalType,
            choices: question.choices.map(choice => choice.value),
            competencies: question.competencies,
            response: Array.isArray(values[`q${question.questionId}`])
                ? values[`q${question.questionId}`]
                : [values[`q${question.questionId}`]],
        }));

        const responseData = {
            userId: userId, // we cannot use currentUser since this can be used from a different navigator - the object currentUser might not be defined
            email: email,
            assessmentId: currentAssessmentId,
            monitoringId: monitoring,
            assessmentType: assessmentType,
            survey: responses,
            sandbox: sandbox,
            displayName: displayName,
        };

        try {
            // TODO CHANGE THIS
            await axios.post(`${BACKEND_URL}/response`, responseData);

            if (currentAssessmentIndex < assessmentIds.length - 1) {
                setCurrentAssessmentIndex(prevIndex => prevIndex + 1);
                setInitialValues({});
                fetchSurveyData(); // Refetch the survey data for the next assessment
                
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                navigate(`/endSurvey?lng=${languageCode}`);
            }
        } catch (error) {
            console.error('Error submitting survey data:', error);
        }

        setSubmitting(false);
    }, [surveyData, assessmentIds, currentAssessmentIndex, languageCode, navigate, fetchSurveyData]);

    const workshops = useMemo(() => {
        return surveyData.reduce((workshops, question) => {
            if (!workshops[question.workshop]) {
                workshops[question.workshop] = [];
            }

            workshops[question.workshop].push(question);
            return workshops;
        }, {});
    }, [surveyData]);

    const handleGenerateCode = () => {
    const consonantPool = "BCDFGHJKLMNPRSTVWXYZ"; 
    const vowelPool = "AEIOU";
    const allChars = consonantPool + vowelPool;

    let code = "";
    // Ensure at least 4 consonants and 4 vowels
    while (code.split("").filter(c => consonantPool.includes(c)).length < 4 ||
          code.split("").filter(c => vowelPool.includes(c)).length < 4) {
      code = "";
      for (let i = 0; i < 8; i++) {
        code += allChars[Math.floor(Math.random() * allChars.length)];
      }
    }

    setLinkingCode(code.toUpperCase());
    setIsCodeValid(true);
  };

  const handleLinkingCodeChange = (e) => {
    const code = e.target.value.toUpperCase();
    setLinkingCode(code);
    setIsCodeValid(code.length > 0); 
    setErrorMessage(code.length === 0 ? "Please enter a linking code." : ''); // Set error message if empty
  };

  const preventEnterKey = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

   return (
        <>
            <Box>
               <DTCLogo />
            </Box>
            {!linkingIDValidated ? (
                <Box m="20px" display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ width: '100%', maxWidth: '400px', mx: 'auto' }}>
                    <Typography variant="h3" mt="50px" mb="10px">{getMessage('complete_survey_linking_code')}</Typography>
                    <TextField
                        variant="outlined"
                        value={linkingCode}
                        onChange={handleLinkingCodeChange}
                        error={!!errorMessage}
                        helperText={errorMessage}
                        inputProps={{
                            maxLength: 8,
                            style: {
                                letterSpacing: '0.5em',
                                textAlign: 'center',
                                fontSize: '2rem',
                                textTransform: 'uppercase'
                            }
                        }}
                        sx={{
                            width: '300px',
                            mb: 2,
                            '& .MuiOutlinedInput-root': {
                                backgroundImage: 'url("data:image/svg+xml,<svg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"300\\" height=\\"24\\"><line x1=\\"0\\" y1=\\"20\\" x2=\\"100%\\" y2=\\"20\\" stroke=\\"#000\\" stroke-width=\\"2\\" stroke-dasharray=\\"6,6\\"/></svg>")',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            },
                            '& input': {
                                textAlign: 'center',
                                fontWeight: 'bold',
                                backgroundColor: 'transparent'
                            }
                        }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={!isCodeValid}
                        onClick={() => setLinkingIDValidated(true)}
                        sx={{
                            backgroundColor: '#F7941E',
                            borderRadius: '50px',
                            color: 'black',
                            '&:hover': { backgroundColor: '#D17A1D' }
                        }}
                    >
                        {getMessage('complete_survey_validate_linking_code')}
                    </Button>
                    <Typography variant="body1" mt="20px" mb="10px">{getMessage('complete_survey_or')}</Typography>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={handleGenerateCode}
                        sx={{
                            backgroundColor: '#F7941E',
                            borderRadius: '50px',
                            color: 'black',
                            '&:hover': { backgroundColor: '#D17A1D' }
                        }}
                    >
                        {getMessage('complete_survey_generate_linking_code')}
                    </Button>
                </Box>
            ) : !nameSubmitted ? (
                 <Box m="20px" display="flex" alignItems="center" justifyContent="center" flexDirection="column" sx={{ width: '100%', maxWidth: '400px', mx: 'auto' }}>
                    <Typography variant="h3" mt="50px" mb="20px">{getMessage("label_enter_displayname")}</Typography>
                    <form onSubmit={handleSubmitName} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <TextField
                            variant="outlined"
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                            required
                            sx={{ width: '100%', maxWidth: '500px', mb: 2 }}
                            inputProps={{
                                maxLength: 30,
                                style: {
                                    letterSpacing: '0.5em',
                                    textAlign: 'center',
                                    fontSize: '2rem',
                                    textTransform: 'uppercase'
                                }
                            }}
                        />
                        <Box display="flex" flexDirection="column" gap="10px" alignItems="center" sx={{ width: '100%' }}>
                            <Button type="submit" variant="contained" sx={buttonStyle}>
                                {getMessage("label_submit")}
                            </Button>

                            <Tooltip title={getMessage("tooltip_anonymous")} arrow>
                                <Button 
                                    type="submit" 
                                    variant="outlined" 
                                    sx={buttonStyle} 
                                    onClick={() => {
                                        setDisplayName('anonymous');
                                    }}
                                >
                                    {getMessage("label_anonymous")}
                                </Button>
                            </Tooltip>
                        </Box>
                    </form>
                </Box>
            ) : (
        // Existing survey rendering logic goes here
        <div>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Box display="flex" alignItems="center" justifyContent="center" sx={{padding: '20px', width: {xs: '90vw', md: '50vw',},}}>

                    {assessmentStatus === "Close" ? (
                        <Typography variant="h3" align="justify" style={{ margin: '10px' }}>
                            {getMessage('label_survey_no_longer_available')}
                        </Typography>
                        ) : assessmentStatus === "Draft" ? (
                        <Typography variant="h3" align="justify" style={{ margin: '10px' }}>
                            {getMessage('label_survey_not_yet_available')}
                        </Typography>
                    ) : null}
                </Box>
            </Box>

            {assessmentStatus !== "Close" && assessmentStatus !== "Draft" && (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Box display="flex" alignItems="center" justifyContent="center" sx={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', borderRadius: '15px', padding: '20px', backgroundColor: '#fff', width: { xs: '90vw', md: '50vw' }, }}>
                        <Formik key={currentAssessmentIndex} initialValues={initialValues} onSubmit={handleSubmit}>
                            {({ setFieldValue }) => (
                                <Form>
                                    {Object.entries(workshops).map(([workshopName, questions]) => (
                                        <div key={workshopName}>
                                            <Box display="flex" justifyContent="center" alignItems="center">
                                                {workshopName !== "undefined" && (
                                                    <Typography variant="h2" fontWeight="bold" color="rgb(102,102,102)" m="15px 10px 0px 0px"> 
                                                        {workshopName}
                                                    </Typography>
                                                )}
                                            </Box>

                                            {questions.map((question, index) => (
                                                <MemoizedSurveyQuestion
                                                    key={index}
                                                    question={question.question}
                                                    context={question.context}
                                                    correctAnswer={question.correctAnswer}
                                                    explanation={question.explanation}
                                                    workshop={question.workshop}
                                                    fieldName={`q${question.questionId}`}
                                                    type={question.questionType}
                                                    isMandatory={question.isMandatory}
                                                    options={question.choices}
                                                    setFieldValue={setFieldValue}
                                                    assessmentType={assessmentType} 
                                                    displayCorrectAnswer={false}
                                                />
                                            ))}
                                        </div>
                                    ))}
                                    {showGlobalError && (
                                        <Box mt={2} display="flex" justifyContent="center">
                                            <Typography color="error">{getMessage('label_questions_required_in_survey')}</Typography>
                                        </Box>
                                    )}
                                    <Box mt={2} display="flex" justifyContent="center">
                                        {surveyData.length > 0 && (
                                            <Button type="submit" variant="contained" sx={buttonStyle}>
                                                <Typography variant="h5">
                                                    {currentAssessmentIndex < assessmentIds.length - 1 ? getMessage('label_next') : getMessage('label_submit')}
                                                </Typography>
                                            </Button>
                                        )}
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            )}
        </div>
        )}
    </>
    );
};

export default CompleteSurvey;

